import React, { useState } from "react";
import { ToastContainer ,toast } from "react-toastify";

import { uploadFile } from "react-s3";

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

const UploadFileToS3WithReactS3 = ({ func_afterUpload , accept='video', ...props}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async (file) => {
    // console.log("UploadFileToS3WithReactS3 file ", file);
    uploadFile(file, config)
      .then((data) => {
        // console.log('data ',data.location);
        func_afterUpload(data.location);
        toast(`Uploaded Succcessfully`)
      })
      .catch((err) => {
        // console.log("err");
        toast(`Uploading Error`)
      });
  };

  return (
    <div className="fileUploader-aws">
      <input type="file" onChange={handleFileInput} name="video" 
      // accept="image/png, image/gif, image/jpeg"
      // accept="video/mp4,video/x-m4v,video/*"
      accept = { `${accept === 'image' ? 'image/png, image/gif, image/jpeg' : 'video/mp4,video/x-m4v,video/' }` }
      />
      <div className="d-flex align-items-center">
        {
          props.setOpenModal &&
          <span onClick={() => props.setOpenModal(true)}>Profile Video</span>
        }
        <button
          type="button"
          className="primary-btn text-white"
          onClick={() => handleUpload(selectedFile)}
          disabled={props.loading}
        >
          {props.loading ? <span>Uploading...</span> : <span>Upload</span>}
        </button>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default UploadFileToS3WithReactS3;
