import React, { useState, useEffect } from "react";
import styles from './styles.css';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { withApollo } from "react-apollo";
import * as commonFunctions from '../../utilities/commonFunctions';
import UserUtils from '../../utilities/userUtils';
import { loader } from "graphql.macro";
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import login from "../login";

const SEARCH_AUTHORS = loader('../../graphql/search/searchAuthors.graphql');

const SearchList = (props) => {

	const [searchKeyword, setSearchKeyword] = useState(null);
	const [searchList, setSearchList] = useState([]);
	const [size, setSize] = useState(10);
	const [pageCount, setPageCount] = useState(1);

	useEffect(() => {
		const keyword = UserUtils.getSearchKeyword();
		console.log('keyword ', typeof keyword)
		if (keyword) {
			setSearchKeyword(keyword);
			$("#loadingDiv").show();
			props.client.query({
				query: SEARCH_AUTHORS,
				variables: {
					data: keyword,
					// "first": size,
					// "page": pageCount
				},
				fetchPolicy: "network-only"
			}).then(response => {
				if (response.data.searchAuthor) {
					setSearchList(response.data.searchAuthor.data);
					console.log('response', response.data.searchAuthor)
					setPageCount(response.data.searchAuthor.paginatorInfo.lastPage);
					setSize(response.data.searchAuthor.paginatorInfo.perPage)
					$("#loadingDiv").hide();
				}
			}).catch(error => {
				let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
				console.log(errorMsg, 'error')
				$("#loadingDiv").hide();
			});

		}
	}, [props, size, pageCount]);

	const handlePage = (data) => {
		let selected = data.selected + 1;
		$("#loadingDiv").show();
		props.client.query({
			query: SEARCH_AUTHORS,
			variables: {
				data: searchKeyword,
				"first": size,
				"page": selected
			},
			fetchPolicy: "network-only"
		}).then(response => {
			console.log('response ', response)
			if (response.data.searchAuthor) {
				setSearchList(response.data.searchAuthor.data);
				setPageCount(response.data.searchAuthor.paginatorInfo.lastPage);
				$("#loadingDiv").hide();
			}
		}).catch(error => {
			let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
			console.log(errorMsg, 'error')
			$("#loadingDiv").hide();
		});
	}

	const handleSearchDetails = (e, id) => {
		const accessToken = UserUtils.getAccessToken();
		UserUtils.setSearchedUserId(id);
		if (accessToken !== null) {
			props.history.push('/search-details');
		} else {
			UserUtils.setSaveUrl('search-details');
			props.history.push('/login');
		}
	}

	return (
		<Typography >
			<div className="search-list">
				<div className="container-1470">
					<div className="row">
						<div className="col-12">

							<h3>Search Results</h3>
						</div>
					</div>
					{/* <div className="search-table">
						<table>
							<thead>
								<th>PROFILE</th>
								<th>NAME</th>
								<th>HOURLY RATE</th>
								<th>QUALIFICATION</th>
								<th>CITY</th>
								<th>STATE</th>
								<th>COUNTRY</th>
								<th>TAG LINE</th>
								<th>Action</th>
							</thead>
							<tbody>
								{(searchList && searchList.length > 0) && searchList.map((d, index) => {
									return (
										<tr key={index}>
											<td>
												<figure className="profile">
													<img src={`${d.avatar !== null ? d.avatar : '/images/default.png'}`} alt="profile" />
												</figure>
											</td>
											<td>{`${d.first_name} ${d.last_name}`}</td>
											<td>{`${d.hourly_rate !== null ? '$' + d.hourly_rate + '/hr' : 'N/A'}`}</td>
											<td>{d.qualification || 'N/A'}</td>
											<td>{d.city || 'N/A'}</td>
											<td>{d.state || 'N/A'}</td>
											<td>{d.country || 'N/A'}</td>
											<td>{d.tag_line || 'N/A'} </td>
											<td><button className="booknow" onClick={e => handleSearchDetails(e, d.id)}>Details</button></td>
										</tr>
									)
								})}
								{(searchList && searchList.length === 0) &&
									<tr>
										<td colSpan="9" className="text-center">
											<h5>Data Not Found. Please try with Another Keyword</h5>
										</td>
									</tr>
								}
							</tbody>
						</table>
					</div> */}
					<div className="serch_list_page">

						<div className="row">
							{(searchList && searchList.length > 0) && searchList.map((d, index) => {
								console.log(d)
								return (
									<div className="col-lg-4" key={index}>
										<div className="search_list_box">
											<figure className="profile_new">
												<img src={`${d.avatar ? `${process.env.REACT_APP_IMAGE_URL}${d.avatar}` : '/images/default.png'}`} alt="profile" />
												<p> {d.city || 'N/A'}, {d.state || 'N/A'}, {d.country || 'N/A'} </p>
											</figure>
											<div className="search_list_content">
												<h5>{`${d.first_name} ${d.last_name}`}</h5>
												<p>{d.tag_line || 'N/A'} </p>

												<ul>
													<li className='experinece_depart'><i class="fa fa-address-card-o" aria-hidden="true"></i> {d.experience || 'N/A'}</li>
													<li className="education_depart">
														<span className="education_new"><i class="fa fa-graduation-cap" aria-hidden="true"></i> {d.qualification || 'N/A'}</span> 
														<span className="hourly_rate" >{`${d.hourly_rate !== null ? '$' + d.hourly_rate + '/hr' : 'Free'}`} </span>
														</li>
													<li><i class="fa fa-certificate" aria-hidden="true"></i> {d.certification || 'N/A'}</li>

													<li><button className="booknow primary-btn" onClick={e => handleSearchDetails(e, d.id)}><span>Details</span></button></li>
												</ul>
											</div>
										</div>
									</div>
								)
							})}
						</div>

						{(searchList && searchList.length === 0) &&
							<tr>
								<td colSpan="9" className="text-center">
									<h5>Data Not Found. Please try with Another Keyword</h5>
								</td>
							</tr>
						}
					</div>
				</div>

				{pageCount > 1
					? <div className="search-pagination">
						<div className="container">
							<ul className="pagination pagination-sm m-0 float-right">
								<ReactPaginate
									previousLabel={<i className="fa fa-angle-double-left"></i>}
									nextLabel={<i className="fa fa-angle-double-right"></i>}
									breakLabel={<a href="#/">...</a>}
									pageCount={pageCount}
									onPageChange={handlePage}
									breakClassName={"break-me"}
									containerClassName={"pagination"}
									subContainerClassName={"pages pagination"}
									activeClassName={"active"}
									pageClassName="page-item"
									pageLinkClassName="page-link" />
							</ul>
						</div>
					</div>
					: ''
				}
			</div>

		</Typography>
	);
};

const enhance = compose(
	withStyles(styles),
	withRouter,
	withApollo
);
export default enhance(SearchList);
