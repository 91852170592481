import React, { useEffect, useRef, useState } from "react";
import styles from '../../css/hamza.css';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import UserUtils from '../../utilities/userUtils';
// import HomeBlogs from "./homeBlogs";
// import $ from 'jquery';
// import { HashLink } from "react-router-hash-link";

// images import
import Storymain from "../../newimages/about.png"
import Arrowimg from "../../newimages/arrow.png"
import posterimg from "../../newimages/hiw.png"
import Dots from "../../newimages/dots.png"
import graphicon from "../../newimages/graph.png"
import Workvideo from "../../newimages/workvideo.mp4"
import Trustedicon from "../../newimages/1.png"
import Easyicon from "../../newimages/2.png"
import fastericon from "../../newimages/3.png"
import Count1 from "../../newimages/count1.png"
import Count2 from "../../newimages/count2.png"
import Count3 from "../../newimages/count3.png"
import Count4 from "../../newimages/count4.png"
// import Video from "twilio-video";
import Mainvideo from '../../components/Mainvideo'


const Home = (props) => {


	const [isLogged, setIslogged] = useState(false);

	useEffect(() => {
		const accessToken = UserUtils.getAccessToken();
		if (accessToken !== null) {
			setIslogged(true);
		}
	}, [props]);
	useEffect(() => {
		document.body.classList.add("home_header");
		return () => {
			document.body.classList.remove("home_header");
		};
	}, [])


	const scrollWithOffset = (el, offset) => {
		const elementPosition = el.offsetTop - offset;
		window.scroll({
			top: elementPosition,
			left: 0,
			behavior: "smooth",
		});
	};

	



	return (
		<Typography >

			{/* <div className="banner">
				<div className="circle_bg">
					<img src="images/circled.png" alt="circle" />
				</div>
				<div className="container" id="home">
					<div className="banner-content">
						<div>

							<h3>Your <span>biggest career</span> move, <br />get started on sqilled</h3>
							<div className="button_block">
								<Link to="/login"><button>Login</button></Link>
							</div>

							<HashLink
                                class='nav-link'
                                to='/#recent-blogs'
								scroll={(el) => scrollWithOffset(el, 100)}
                                >
                                <i class="las la-arrow-down"></i>
                              </HashLink>
						</div>
					</div>

				</div>
			</div>
			<div id="recent-blogs">
				<HomeBlogs />
			</div>
			<div className="how-it-works-main how-it-works-main-squilled" id="howit_works">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<div className="peopeles_left text-center" >
								<h3>How it works</h3>
								<h4>You sign up on our platform with a tag-line about your strength.</h4>
								<p>Make a short video of your portfolio of services.
									Adjust your availability and set your hourly rate. And that is it. You are ready to provide sqilled services to the world.</p>

							</div>

						</div>
						<div className="col-sm-7">
							
						</div>
					</div>
				</div>
			</div>

			<div className="about" id="aboutus">
				<div className="container">
					<div>
						<h4><span >Our Story</span></h4>
						<h3>Welcome to Sqilled. Your biggest career move. </h3>
						<p>We are creating this platform to empower every individual to offer their skill and services throughout the globe.The idea is to democratize the creator’s economy, where everyone who has a skill, can earn a living by being compensated for providing those skills and services </p>
						<p>Our aim is to ensure every sqilled person on this planet, in every section of the economy, is connected to one another through this platform and work towards a common goal of providing services and improving lives of other individuals and corporations on a click of button.</p>
					</div>
				</div>
			</div> */}





			{/* banner  */}
			<section className="hz-banner">
				<div className="container-1470">
					<div className="hz-banner-content">
						<button className="banner-btn">Your Biggest Career Move</button>
						<h1>Get Started On Sqilled</h1>

					</div>
					{/* <div className="banner-bottom">
						<div className="banner-box">
							<div className="bb-imgs">
								<img src="" alt="" />
								<span className="red-circle"></span>
								<img className="red-dots" src="" alt="" />
							</div>
							<h2>FOCUS ON <span>GOALS</span></h2>
						</div>
					</div> */}
				</div>
			</section>
			{/* banner end  */}


			{/* achivement  */}
			{/* <section className="achivements">
				<div className="container-1470">
					<div className="choose-top-content">
						<h2>Achievement We Have Earned</h2>
						<p>At Freelancer, We believe that talent is borderless and opportunity should be too.</p>
					</div>
					<div className="counter-sec">
						<div className="count-box">
							<img src={Count1} alt="" />
							<h2>857M</h2>
							<p>Total Freelancers</p>
						</div>
						<div className="count-box">
							<img src={Count2} alt="" />
							<h2>627M</h2>
							<p>Positive Review</p>
						</div>
						<div className="count-box">
							<img src={Count3} alt="" />
							<h2>87M</h2>
							<p>Order Received</p>
						</div>
						<div className="count-box">
							<img src={Count4} alt="" />
							<h2>727M</h2>
							<p>Project Completed</p>
						</div>
					</div>
				</div>
			</section> */}
			{/* achivement end */}

			{/* our story   */}
			<section className="story">
				<div className="container-1470">
					<div className="row align-items-center">
						<div className="col-md-6">
							<div className="leftimg">
								<img src={Storymain} alt="" />
								<span className="red-circle"></span>
								<span className="blue-circle"></span>
							</div>
						</div>
						<div className="col-md-6">
							<div className="story-content">
								<h2>Our Story</h2>
								<h3>Welcome to Sqilled. Your biggest career move.</h3>
								<p>We are creating this platform to empower every individual to offer their skill and services throughout the globe.The idea is to democratize the creator’s economy, where everyone who has a skill, can earn a living by being compensated for providing those skills and services
									<br /><br />
									Our aim is to ensure every sqilled person on this planet, in every section of the economy, is connected to one another through this platform and work towards a common goal of providing services and improving lives of other individuals and corporations on a click of button.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our story end  */}

			{/* work   */}
			<section id="work" className="work">
				<div className="container-1470">
					<div className="row align-items-center">
						<div className="col-md-6">
							<div className="work-content">
								<h2>How it works</h2>
								<h3>You sign up on our platform with a tag-line about your strength.</h3>
								<p>Make a short video of your portfolio of services. Adjust your availability and set your hourly rate. And that is it. You are ready to provide sqilled services to the wor</p>
								<div id="accordion" className="accordion-main">
									<div className="collapse-box">
										<div className="collapse-header" data-toggle="collapse" data-target="#collapseone" aria-expanded="false" aria-controls="collapseOne">
											<div className="row no-gutters align-items-center"  >
												<div className="col-2">
													<span className="collapse-number">1</span>
												</div>
												<div className="col-8">
													<h2>Complete Profile</h2>
												</div>
												<div className="col-2 text-center">
													<img src={Arrowimg} alt="" />
												</div>
											</div>
										</div>
										<div id="collapseone" className="collapse fade row no-gutters" data-parent="#accordion">
											<div className="col-2"></div>
											<div className="col-10">
												<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
											</div>
										</div>
									</div>
									<div className="collapse-box">
										<div className="collapse-header" data-toggle="collapse" data-target="#collapsetwo" aria-expanded="false" aria-controls="collapseOne">
											<div className="row no-gutters align-items-center"  >
												<div className="col-2">
													<span className="collapse-number">2</span>
												</div>
												<div className="col-8">
													<h2>Search Vacancies</h2>
												</div>
												<div className="col-2 text-center">
													<img src={Arrowimg} alt="" />
												</div>
											</div>
										</div>
										<div id="collapsetwo" className="collapse fade row no-gutters" data-parent="#accordion">
											<div className="col-2"></div>
											<div className="col-10">
												<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
											</div>
										</div>
									</div>
									<div className="collapse-box">
										<div className="collapse-header" data-toggle="collapse" data-target="#collapsethree" aria-expanded="false" aria-controls="collapseOne">
											<div className="row no-gutters align-items-center"  >
												<div className="col-2">
													<span className="collapse-number">3</span>
												</div>
												<div className="col-8">
													<h2>Apply Jobs</h2>
												</div>
												<div className="col-2 text-center">
													<img src={Arrowimg} alt="" />
												</div>
											</div>
										</div>
										<div id="collapsethree" className="collapse fade row no-gutters" data-parent="#accordion">
											<div className="col-2"></div>
											<div className="col-10">
												<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="work-video">
								<div className="work-video-inner">
									<Mainvideo posterimg={posterimg} videoUrl={Workvideo}/>
									<div className="imgs">
										<img className="dots-img" src={Dots} alt="" />
										<img className="graph-img" src={graphicon} alt="" />
										<span className="red-circle"></span>
										<span className="blue-circle"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* work end  */}

			{/* why choose us   */}
			<section id="whychooseus" className="choose-sec">
				<div className="container-1470">
					<div className="choose-top-content">
						<h2>Why Choose Us</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
					</div>
					<div className="choose-wrapper">
						<div className="choose-box">
							<img src={Trustedicon} alt="" />
							<h2>Trusted</h2>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
						</div>
						<div className="choose-box">
							<img src={Easyicon} alt="" />
							<h2>Easy to Use</h2>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
						</div>
						<div className="choose-box">
							<img src={fastericon} alt="" />
							<h2>Faster</h2>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
						</div>
					</div>
				</div>
				<div className="choose-circle">
					<span className="red-circle"></span>
					<span className="blue-circle"></span>
				</div>
			</section>
			{/* why choose us end  */}
		</Typography>
	);
};

const enhance = compose(
	withStyles(styles),
	withRouter,
);
export default enhance(Home);
