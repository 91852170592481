import React, { useEffect, useRef, useState } from "react"
import Footernew from "../../components/ui/footernew/footer"
import Storyimg from "../../newimages/story.png"
import Aboutimg from "../../newimages/about3.png"
import Trustedicon from "../../newimages/1.png"
import Easyicon from "../../newimages/2.png"
import fastericon from "../../newimages/3.png"
import Mainvideo from "../../components/Mainvideo"
import posterimg from "../../newimages/about2.png"
import Workvideo from "../../newimages/workvideo.mp4"

const About = (props) => {

 
    

    return (
        <>
            {/* about banner   */}
            <section class="about-banner">
                <div class="container-1470">
                    <div class="ab-banner-wrapper">
                        <div class="ab-banner-left">
                            <h2>About Us</h2>
                            <div class="ab-bl-inner">
                                <h2>Welcome to Sqilled. Your biggest career move.</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                <img src={Aboutimg} alt="" />
                                <span class="red-circle"></span>
                            </div>
                        </div>
                        <div class="ab-banner-right">
                            <div class="work-video position-relative ab-work-video">
                                <div class="work-video-inner">
                                    <Mainvideo posterimg={posterimg} videoUrl={Workvideo}/>
                                    <div class="imgs">
                                        <span class="blue-circle"></span>
                                    </div>
                                </div>
                            </div>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* about banner end   */}

            {/* our story */}
            <section class="story about lightbue-bg">
                <div class="container-1470">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="story-content">
                                <h2>Our Story</h2>
                                <h3>Welcome to Sqilled. Your biggest career move.</h3>
                                <p>We are creating this platform to empower every individual to offer their skill and services throughout the globe.The idea is to democratize the creator’s economy, where everyone who has a skill, can earn a living by being compensated for providing those skills and services
                                    <br /><br />
                                    Our aim is to ensure every sqilled person on this planet, in every section of the economy, is connected to one another through this platform and work towards a common goal of providing services and improving lives of other individuals and corporations on a click of button.</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="leftimg">
                                <img src={Storyimg} alt="" />
                                <span class="red-circle"></span>
                                <span class="blue-circle"></span>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* our story end */}

            {/* why choose us   */}
            <section id="whychooseus" className="choose-sec">
                <div className="container-1470">
                    <div className="choose-top-content">
                        <h2>Why Choose Us</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                    </div>
                    <div className="choose-wrapper">
                        <div className="choose-box">
                            <img src={Trustedicon} alt="" />
                            <h2>Trusted</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                        </div>
                        <div className="choose-box">
                            <img src={Easyicon} alt="" />
                            <h2>Easy to Use</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                        </div>
                        <div className="choose-box">
                            <img src={fastericon} alt="" />
                            <h2>Faster</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                        </div>
                    </div>
                </div>
                <div className="choose-circle">
                    <span className="red-circle"></span>
                    <span className="blue-circle"></span>
                </div>
            </section>
            {/* why choose us end  */}
        </>
    )
}
export default About