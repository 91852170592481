import React from "react"
import { useEffect, useRef, useState } from "react";
import Playicon from "../../newimages/play.png"
import Pauseicon from "../../newimages/pause.png"


const Mainvideo = (props) => {

    
    const [isPlaying, setIsPlaying] = useState(false)
    const videoRef = useRef();


    useEffect(() => {
        if (isPlaying) {
            videoRef.current.play();
        }else{
            videoRef.current.pause();
        }
    }, [isPlaying])

    // 

    


    return (
        <>
            <div className="videp-wrap">
                <video controls={false} poster={props.posterimg} ref={videoRef}>
                    <source src={props.videoUrl} type="video/mp4" />
                </video>
                <div className="play-button">
                    {<button onClick={() => setIsPlaying(!isPlaying)}  >

                        {isPlaying ? <img src={Pauseicon} alt="play" />
                        
                        : <img src={Playicon} alt="pause" />}
                        
                    </button>}
                </div>
            </div>
        </>
    )
}
export default Mainvideo;