import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import UserUtils from "../../utilities/userUtils";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { loader } from "graphql.macro";

import styles from "./style.css";
import { toast } from "react-toastify";
import UtcToLocalTime from "../../utilities/utcToLocalTime";

const USER = loader("../../graphql/auth/user.graphql");
const DELETE_BLOG = loader('../../graphql/search/blogDelete.graphql');

const MyWork = (props) => {
  const history = useHistory();
  const [userBlogs, setUserBlogs] = useState([]);
  const [fectAfterDelete, setFectAfterDelete] = useState(false);

  const handleDelete = (id) => {
    console.log('id ', id);
    props.client.mutate({
      mutation: DELETE_BLOG,
      variables: {
        data: {
          blog_id: id
        }
      }
    }).then(response => {
      toast("Blog Deleted SuccessFully");
      setFectAfterDelete(!fectAfterDelete);

    }).catch(error => {
      toast("Error Deleting Blog");
    });

  }

  useEffect(() => {
    const accessToken = UserUtils.getAccessToken();
    if (accessToken === null) {
      props.history.push("/login");
    }
    const userId = UserUtils.getUserID();
    if (userId === null) {
      props.history.push("/login");
    } else {
      props.client
        .query({
          query: USER,
          variables: {
            data: userId,
          },
          fetchPolicy: "network-only",
        })
        .then((response) => {
          if (response?.data?.user) {
            setUserBlogs(response?.data?.user?.blog);
          }
        })
        .catch((error) => {
          console.log("error ", error);
        });
    }
  }, [props, fectAfterDelete]);

  




  return (
    <>
      <section className='blogsSec'>
        <div className='container-940'>
          <div className='row m-0 mb-4'>
            <div className='col-12'>
              <div className="d-flex justify-content-center">
                <button
                
                  type='button'
                  onClick={() => {
                    history.push('/create-blog');
                  }}
                  className='primary-btn m-auto'>
                  <span>Create Blog</span>
                </button>
              </div>
            </div>
          </div>
          <div className='row m-0'>
            {userBlogs?.map((blog) => {
              return (
                <div
                  className='col-12'
                  key={blog.id}
                >
                  <article className='blogBox'>
                    <div className='autohrBox'>
                      <div className='authorImg'>
                        <img src={blog?.user?.avatar ? blog?.user?.avatar : '/images/default.png'} alt='Blogger' />
                      </div>
                      <div className='authorInfo'>
                        <h3 className='authorName'>
                          {blog?.user?.first_name} {blog?.user?.last_name}
                        </h3>
                        <ul className='articleDate'>
                          <li>
                            <UtcToLocalTime created_at={blog?.created_at} />
                          </li>
                        </ul>
                      </div>
                      <span className="blog-editBtn" onClick={() => props.history.push(`/edit-blogs:${blog.id}`)} >
                        <i class="fa-solid fa-pen-to-square"></i>
                      </span>
                      <span className="blog-deleteBtn" onClick={() => handleDelete(blog.id)} >
                        <i class="fa-solid fa-trash"></i>
                      </span>

                    </div>
                    <div className='blogContent'>
                      <h2> {blog.title} </h2>
                      <p> {blog.description} </p>
                      <span 
                      className="blog-readmore-btn"
                      // type="button"
                        onClick={() => {
                          history.push({
                            pathname: '/blog',
                            search: `id:${blog.id}`,
                          });
                        }}
                      >Read More</span>
                    </div>
                  </article>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

const enhance = compose(withStyles(styles), withRouter, withApollo);
export default enhance(MyWork);
