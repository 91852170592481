import React from 'react'
import { Modal } from "react-responsive-modal";

const VideoModal = ({openModal,setOpenModal,videoUrl}) => {
    return (
        <>

            <Modal open={openModal} onClose={()=>setOpenModal(false)} closeOnOverlayClick={false}>
                <div className="video-play">
                    <h3>Profile Video</h3>
                    {videoUrl === "" && <p>Video Not Found</p>}
                    <div className='player-wrapper'>
                        <div style={{ position: 'relative', paddingBottom: '62.5%', height: 0, }}>
                            <iframe src={videoUrl} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></iframe>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default VideoModal
