import React, { useState, useEffect, useRef } from "react";
import compose from "recompose/compose";
import { withRouter, Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import globalStyles from "../../layout/globalStyles";
import styles from "./styles.css";
import combineStyles from "../../../utilities/combineStyles";
import * as commonFunctions from "../../../utilities/commonFunctions";
import UserUtils from "../../../utilities/userUtils";
import SearchInput from "../../../screens/search-list/searchInput";
import { loader } from "graphql.macro";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

import Logo from "../../../newimages/logomain.png"

import { toast } from "react-toastify";
import { HashLink } from "react-router-hash-link";
import { onMessageListener } from "../../../screens/login/firebase";
const USER = loader("../../../graphql/auth/user.graphql");


const Header = (props) => {
  const [userID, setUserID] = useState(null);
  const [profileImage, setProfileImage] = useState("");
  // const [userName, setUsername] = useState("");
  const [userType, setUserType] = useState("");

  const [showMobNav, setShowMobNav] = useState(false);

  const handleScroll = () => {
    let header = document.getElementById("header-sec");
    const scrollpos = window.scrollY;
    if (scrollpos > 100) {
      header.classList.add("active");
    } else {
      header.classList.remove("active");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const userId = UserUtils.getUserID();
    if (userId !== null) {
      setUserID(userId);
      props.client
        .query({
          query: USER,
          variables: {
            data: userId,
          },
          fetchPolicy: "network-only",
        })
        .then((response) => {
          if (response.data.user) {
            // setUsername(
            //   response.data.user.first_name + " " + response.data.user.last_name
            // );
            setProfileImage(response.data.user.avatar);
            setUserType(response.data.user.type);
            localStorage.setItem("userType", response.data.user.type);
            localStorage.setItem("userEmail", response.data.user.email);
          }
        })
        .catch((error) => {
          let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
          console.log(errorMsg, "error");
        });

      // const messaging = firebase.messaging();

      onMessageListener()
        .then((payload) => {
          if (payload.notification.title) {
            callToaster(payload);
          }
        })
        .catch((err) => console.log("failed: ", err));
    }
  }, [props]);
  const logOut = (e) => {
    e.preventDefault();
    setShowDiv(false);
    props.history.push("/login");
    UserUtils.logout();
  };

  const callToaster = (payload) => {
    toast.success(
      `${payload.notification.title} ${payload.notification.body}`,
      {
        position: toast.POSITION.TOP_RIGHT,
        onClose: () => toasterCallBackFunc(),
      }
    );
  };

  const toasterCallBackFunc = () => {
    if (props.history.location.pathname !== "/video-chat") {
      props.history.push("/my-bookings");
    }
  };
  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };


  const [showDiv, setShowDiv] = useState(false);

  const toggleDiv = () => {
    setShowDiv(!showDiv);
  };


  const mobNavigate = (route) => {
    setShowDiv(false);
    props.history.push(`${route}`);
  }


  return (
    <React.Fragment>
      <header className="mainheader" id="header-sec">
        <div className="container-1470">
          <div className="row align-items-center justify-content-between no-gutters">
            <div className="col-lg-3 col-8">
              <div className="brand">
                <Link to="/"><img src={Logo} alt="" /></Link>
              </div>
            </div>
            <div className="col-lg-9 col-4">
              <button class="togglebtn d-lg-none d-md-flex" onClick={() => setShowMobNav(!showMobNav)} >
                <i class="fas fa-bars"></i>
              </button>
              <nav className="navigation d-lg-block d-none">
                <ul className="d-flex align-items-center justify-content-end m-0 main-menu">
                  <li><Link to="/">Home</Link></li>
                  <li><HashLink to='/#whychooseus'>Why Choose Us</HashLink></li>
                  <li><Link to="/about">About Us</Link></li>
                  {
                    userID !== null ?
                      <li><Link to="/my-work">My Work</Link></li> : ''
                  }
                  <li className="search-form">
                    <SearchInput />
                  </li>
                  {
                    userID === null ?
                      <>
                        <li><Link to="/login" className="primary-btn"><span>Login</span></Link></li>
                        <li className="signup"><Link to="/register" href="#" className="secondary-btn"><span>Sign Up</span></Link></li>
                      </>
                      :
                      <li className="profile-list">
                        <img onClick={toggleDiv} className="profile-icon"
                          src={`${profileImage !== null && profileImage !== ""
                            ? `${process.env.REACT_APP_IMAGE_URL}${profileImage}`
                            : "/images/default.png"
                            }`}
                          alt="" />
                        {showDiv && <ul>
                          <li><Link to="/view-profile">My Profile</Link></li>
                          <li><Link to="/edit-profile">Edit Profile</Link></li>
                          <li><Link to="/my-bookings">My Bookings</Link></li>
                          {userType === "W" ? (
                            <li>
                              <Link to='/my-Subscribers'>
                                My Subscribers
                              </Link>
                            </li>
                          ) : null}
                          {userType === "W" ? (
                            <li className='trade_btn'>
                              <a
                                href='https://www.coinbase.com/'
                                target='_blank'
                                rel='noreferrer'>
                                Invest
                              </a>
                            </li>
                          ) : null}
                          <li onClick={logOut}><Link>Log Out</Link></li>
                        </ul>}
                      </li>
                  }
                </ul>
              </nav>

              {/* Mobile header  */}
              <nav className={`navigation d-lg-none d-md-block ${showMobNav ? 'mob_navigation' : ''}`}>
                <div class="mobile-logo  d-lg-none d-md-flex">
                  <Link to="/"><img src={Logo} alt="" /></Link>
                  <button class="btn-close" onClick={() => setShowMobNav(!showMobNav)}><i class="fas fa-times"></i></button>
                </div>
                <ul className="d-flex align-items-center justify-content-end m-0 main-menu">
                  <li onClick={() => mobNavigate('/')} >Home</li>
                  <li onClick={() => mobNavigate('/')} >Why Choose Us</li>
                  <li onClick={() => mobNavigate('/about')} > About Us </li>
                  {
                    userID !== null ?
                      <li onClick={() => mobNavigate('/my-work')} > My Work </li> : ''
                  }
                  <li className="search-form">
                    <SearchInput />
                  </li>
                  {
                    userID === null ?
                      <>
                        <li onClick={() => mobNavigate('/login')} ><div  className="primary-btn"><span>Login</span></div></li>
                        <li className="signup" onClick={() => mobNavigate('/register')}  ><div className="secondary-btn"><span>Sign Up</span></div></li>
                      </>
                      :
                      <li className="profile-list">
                        <img onClick={toggleDiv} className="profile-icon"
                          src={`${profileImage !== null && profileImage !== ""
                            ? `${process.env.REACT_APP_IMAGE_URL}${profileImage}`
                            : "/images/default.png"
                            }`}
                          alt="" />
                        {showDiv && <ul>
                          <li onClick={() => mobNavigate('/view-profile')} >My Profile</li>
                          <li onClick={() => mobNavigate('/edit-profile')} >Edit Profile</li>
                          <li onClick={() => mobNavigate('/my-bookings')} >My Bookings</li>
                          {userType === "W" ? (
                            <li onClick={() => mobNavigate('/my-Subscribers')} >
                              My Subscribers
                            </li>
                          ) : null}
                          {userType === "W" ? (
                            <li className='trade_btn'>
                              <a
                                href='https://www.coinbase.com/'
                                target='_blank'
                                rel='noreferrer'>
                                Invest
                              </a>
                            </li>
                          ) : null}
                          <li onClick={logOut}>Log Out</li>
                        </ul>}
                      </li>
                  }
                </ul>
              </nav>
              {/* Mobile header  */}

            </div>
          </div>
        </div>
      </header>




      {/* <div className='header-sec' id='header-sec'>
        <div className='container'>
          <div className='header-nav'>
            <div className='nav-left'>
              <h3>
                <Link to='/'>
                  <img className='imageLogo' src='images/logo2.png' />
                </Link>
              </h3>
            </div>
            <div className='nav-right'>
              <nav className='navbar navbar-expand-lg'>
                <button
                  className='navbar-toggler'
                  type='button'
                  data-toggle='collapse'
                  data-target='#navbarSupportedContent'
                  aria-controls='navbarSupportedContent'
                  aria-expanded='false'
                  aria-label='Toggle navigation'>
                  <span className='navbar-toggler-icon'>
                    <i className='fa fa-bars'></i>
                  </span>
                </button>

                <div
                  className='collapse navbar-collapse'
                  id='navbarSupportedContent'>

                  {userID !== null ? (
                    <React.Fragment>
                      <div className='top-bar-right'>
                        <div class='right-flex'>
                          <ul class='navbar-nav nav-right'>
                            <li class='nav-item'>
                              <HashLink
                                class='nav-link'
                                to='/#home'
                                scroll={(el) => scrollWithOffset(el, 10)}>
                                Home
                              </HashLink>
                            </li>
                            <li class='nav-item'>
                              <HashLink
                                class='nav-link'
                                to='/#howit_works'
                                scroll={(el) => scrollWithOffset(el, 80)}>
                                How it works
                              </HashLink>
                            </li>
                            <li class='nav-item'>
                              <HashLink
                                class='nav-link'
                                to='/#aboutus'
                                scroll={(el) => scrollWithOffset(el, 110)}>
                                About us
                              </HashLink>
                            </li>
                            <li class='nav-item'>
                              <Link
                                class='nav-link'
                                to='/my-work'
                                scroll={(el) => scrollWithOffset(el, 110)}>
                                My Work
                              </Link>
                            </li>
                          </ul>
                          <div className='profile-block-nav'>
                            <figure>
                              <img
                                src={`${
                                  profileImage !== null && profileImage !== ""
                                    ? `${process.env.REACT_APP_IMAGE_URL}${profileImage}`
                                    : "/images/default.png"
                                }`}
                                alt='profile'
                              />
                            </figure>

                            <div className='header-toggle'>
                              <ul>
                                <li>
                                  <Link to='/view-profile'>My Profile</Link>
                                </li>
                                <li>
                                  <Link to='/edit-profile'>Edit Profile</Link>
                                </li>
                                <li>
                                  <Link to='/my-bookings'>My Bookings</Link>
                                </li>
                                {userType === "W" ? (
                                  <li>
                                    <Link to='/my-Subscribers'>
                                      My Subscribers
                                    </Link>
                                  </li>
                                ) : null}

                                <li>
                                  <Link to='/#' onClick={logOut}>
                                    Log out
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {userType === "W" ? (
                            <button className='trade_btn'>
                              <a
                                href='https://www.coinbase.com/'
                                target='_blank'
                                rel='noreferrer'>
                                Invest
                              </a>
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <ul class='navbar-nav nav-right'>
                        <li class='nav-item'>
                          <HashLink
                            class='nav-link'
                            to='/#home'
                            scroll={(el) => scrollWithOffset(el, 10)}>
                            Home
                          </HashLink>{" "}
                        </li>
                        <li class='nav-item'>
                          <HashLink
                            class='nav-link'
                            to='/#howit_works'
                            scroll={(el) => scrollWithOffset(el, 80)}>
                            How it works
                          </HashLink>{" "}
                        </li>
                        <li class='nav-item'>
                          <HashLink
                            class='nav-link'
                            to='/#aboutus'
                            scroll={(el) => scrollWithOffset(el, 110)}>
                            About us
                          </HashLink>
                        </li>
                        <li>
                          <p>{userName}</p>
                        </li>
                      </ul>
                    </React.Fragment>
                  )}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
};

const combinedStyles = combineStyles(globalStyles, styles);

const enhance = compose(withStyles(combinedStyles), withRouter, withApollo);
export default enhance(Header);
