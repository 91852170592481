// Component to create blogs using EDITOR.js

import { default as React, useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import { default as HeaderEditor } from "@editorjs/header";
import List from "@editorjs/list";
import styles from "./style.css";
import { loader } from "graphql.macro";
import { withStyles } from '@material-ui/core/styles';
import { useHistory, withRouter, useLocation } from 'react-router-dom';
import { withApollo } from "react-apollo";

import ImageTool from "@editorjs/image";
import UserUtils from "../../utilities/userUtils";

import { toast } from "react-toastify";

import UploadFileToS3WithReactS3 from "../../utilities/uploadFileToS3WithReactS3";
import { useMutation } from "@apollo/react-hooks";
import compose from 'recompose/compose';

const BLOG_EDIT = loader("../../graphql/search/blogEdit.graphql");
const BLOG_GET = loader("../../graphql/search/blogGet.graphql");


const DEFAULT_INITIAL_DATA = () => {
  return {
    time: new Date().getTime(),
    blocks: [
      {
        type: "paragraph",
        data: {
          text: "Enter text here...",
          level: 1,
        },
      },
    ],
  };
};

const Quote = require("@editorjs/quote");
const EDITTOR_HOLDER_ID = "editorjs";

const EditBlog = (props) => {
  const history = useHistory();
  const loaction = useLocation();
  const [userBlogs, setUserBlogs] = useState({});
  const [editorData, setEditorData] = React.useState(DEFAULT_INITIAL_DATA);
  // const [editorData, setEditorData] = React.useState(userBlogs?.text);
  const [editAble, seteditAble] = useState(true);
  const [EnableEditor, setEnableEditor] = useState(false);

  const [ThumbnailUrl, setThumbnailUrl] = useState("");
  const [VideoUrl, setVideoUrl] = useState("");

  const [BlogTitle, setBlogTitle] = useState("");
  const [BlogDecp, setBlogDecp] = useState("");

  const [ThumbnailAlt, setThumbnailAlt] = useState("");
  const [data, setData] = useState([]);


  const ejInstance = useRef(null);
  const initEditor = () => {
    setEnableEditor(true);
    const editor = new EditorJS({
      holder: EDITTOR_HOLDER_ID,
      logLevel: "ERROR",
      data: editorData,
      onReady: () => {
        if (ejInstance.current !== null && ejInstance.current !== undefined) {
          ejInstance.current = editor;
        }
      },
      onChange: async () => {
        let content = await editor.saver.save();
        setEditorData(content);
      },
      autofocus: true,
      tools: {
        header: HeaderEditor,
        quote: Quote,
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: "unordered",
          },
        },
        image: {
          class: ImageTool,
          
          config: {
            endpoints: {
              byFile: `http://192.168.0.26:5000/api/v1/file/image/editor`, // Your backend file uploader endpoint
            },
          },
        },
      },
    });
  };

  const postRes = (data) => {
    console.log("data.message", data.message);
  };

  const [mutate] = useMutation(BLOG_EDIT, {
    onCompleted({ editBlog: { status: status, message: message } }) {
      postRes({ status, message });
    },
    onError(error) {
      console.log(error);
    },
  });


  const handleSubmit = () => {
    // console.log(editorData.blocks);
    let payload = {
      title: BlogTitle,
      blog_id: loaction.pathname.split(":")[1],
      // thumbnail_alt: ThumbnailAlt,
      // thumbnail: ThumbnailUrl,
      thumbnail_alt: 'null',
      thumbnail: 'null',
      description: BlogDecp,
      video_url: VideoUrl,
      text: JSON.stringify({
        value: {
          content: editorData.blocks,
        },
      }),
    };
    props.client
      .mutate({
        mutation: BLOG_EDIT,
        variables: {
          data: payload
        }
      })
      .then((response) => {
        if (response) {
          toast("Blog Edited Suceessfully!")
          history.push('/my-work')
        }
      })
      .catch((error) => {
        toast(`Error ${error?.message}`)
      });
  };

  const uploadThumbnail = (thumbnail_url) => {
    setThumbnailUrl(thumbnail_url);
  };


  useEffect(() => {
    const accessToken = UserUtils.getAccessToken();
    if (accessToken === null) {
      props.history.push("/login");
    }
    const userId = UserUtils.getUserID();
    if (userId === null) {
      props.history.push("/login");
    } else {
      props.client
        .query({
          query: BLOG_GET,
          variables: {
            //GET BLOG WITH ID
            data: loaction.pathname.split(":")[1],
          },
          fetchPolicy: "network-only",
        })
        .then((response) => {
          if (response) {
            setUserBlogs(response?.data?.blog);
            setBlogTitle(response?.data?.blog?.title);
            setBlogDecp(response?.data?.blog?.description);
            setThumbnailUrl(response?.data?.blog?.thumbnail)
            setThumbnailAlt(response?.data?.blog?.thumbnail_alt);
            setVideoUrl(response?.data?.blog?.video_url)

            let a = [];
            let text = JSON.parse(response?.data?.blog.text);
            for (let i = 0; i < text.value.content.length; i++) {
              if (text.value.content[i].type == "image") {
                text.value.content[i] = {
                  ...text.value.content[i],
                  data: {
                    ...text.value.content[i].data,
                    url: text.value.content[i].data.file.url,
                  },
                };
              }
              a.push({
                type: text.value.content[i].type,
                data: text.value.content[i].data,
              });
            }
            let b = {
              time: new Date().getTime(),
              blocks: a,
            };
            setEditorData(b);
            setData(text);
          }
        })
        .catch((error) => {
          console.log("error ", error);
        });
    }
  }, [props]);

  useEffect(() => {
    setEditorData(userBlogs?.text)
  }, [userBlogs])

  const uploadVideo = (Video_url) => {
    setVideoUrl(Video_url);
  };


  return (
    <>
      <section className='blogsSec'>
        <div className='container-940'>
          <div className='row m-0'>
            <div className='col-12'>
              <div className='createNewBlog'>
                <h2>Edit Blog</h2>
                <form action=''>
                  <div className='form-group'>
                    <label>Blog Title</label>
                    <input
                      type='text'
                      className='form-control'
                      value={BlogTitle}
                      onChange={(e) => setBlogTitle(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label>Blog Description</label>
                    <input
                      type='text'
                      className='form-control'
                      value={BlogDecp}
                      onChange={(e) => setBlogDecp(e.target.value)}
                    />
                  </div>
                  
                  {/* <div className='form-group mb-0'>
                    <label>BLog Thumbnail</label>
                  </div>
                  {
                    ThumbnailUrl === '' ?
                      <UploadFileToS3WithReactS3
                        func_afterUpload={uploadThumbnail}
                        accept = 'image'
                      />
                      :
                      <div className="blog-thumbnail-preview">
                        <img src={ThumbnailUrl} />
                        <span><i class="fa-solid fa-xmark" onClick={() => setThumbnailUrl('')}></i></span>
                      </div>
                  } */}
                  
                  {/* <div className='form-group'>
                    <label>BLog Thumbnail Alt</label>
                    <input
                      type='text'
                      value={ThumbnailAlt}
                      className='form-control'
                      onChange={(e) => setThumbnailAlt(e.target.value)}
                    />
                  </div> */}

                  <div className='form-group mb-0'>
                    <label>BLog Video</label>
                  </div>
                  {
                    (!VideoUrl) ?
                      <UploadFileToS3WithReactS3
                        func_afterUpload={uploadVideo}
                        accept = 'video'
                      />
                      :
                      <div className="blog-thumbnail-preview">
                        <video autoPlay className="m-0" >
                          <source src={VideoUrl} />
                        </video>
                        <span><i class="fa-solid fa-xmark" onClick={() => setVideoUrl('')}></i></span>
                      </div>
                  }

                  {/* <div className='form-group'>
                    <label>BLog Video</label>
                    <input type='file' className='form-control'  />
                  </div> */}
                  <div className='form-group'>
                    <label>Blog Content</label>
                    <div className='blog-textArea'>
                      <div id={EDITTOR_HOLDER_ID}></div>
                      {!EnableEditor ? (
                        <button
                          className='editorJsbtn secondary-btn'
                          type='button'
                          style={{ color: "white" }}
                          onClick={() => initEditor()}>
                          {editAble === true
                            ? <span>Start Updating</span>
                            : <span>Start Creating</span>}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <button
                    type='button'
                    onClick={() => {
                      handleSubmit();
                    }}
                    className='btn primary-btn d-block ml-auto mr-0'>
                    <span>Submit</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <ToastContainer /> */}
    </>
  );
};
const enhance = compose(
  withStyles(styles),
  withRouter,
  withApollo,
);
export default enhance(EditBlog);
