import React from "react"
import Logo from '../../../newimages/logofoot.png'
import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faInstagram, faLinkedin, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faLocation, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons"
// import { FontAwesomeIcon } from "@fortawesome/"


const Footernew = (props) => {
    return (
        <>
             {/* footer   */}
            <footer class="main-footer">
                <div class="container-1470">
                    <div class="row foot-content-row no-gutters">
                        <div class="col-lg-6 col-md-5">
                            <div class="foot-content">
                                <Link to="/"><img src={Logo} alt="" /></Link>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                                <div class="social-media">
                                <div className="social-icon">
                                    <FontAwesomeIcon icon={faFacebookF} />
                                </div>                                    
                                <div className="social-icon">
                                    <FontAwesomeIcon icon={faTwitter} />
                                </div>                                
                                <div className="social-icon">
                                    <FontAwesomeIcon icon={faYoutube} />
                                </div>                                
                                <div className="social-icon">
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </div>                                
                                <div className="social-icon">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </div>                                
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2  col-sm-4">
                            <div class="foot-links-wrap">
                                <h2>Resources</h2>
                                <ul class="foot-link-list">
                                    <li><Link to="/login">Login</Link></li>
                                    <li><Link to="/register">Sign Up</Link></li>
                                    <li><Link to="/terms">Terms & Conditions</Link></li>
                                    <li><Link to="/privacy">Privacy Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2  col-sm-4">
                            <div class="foot-links-wrap">
                                <h2>Company</h2>
                                <ul class="foot-link-list">
                                    <li><Link to="/about">About us</Link></li>
                                    <li><Link to="/contact">Contact us</Link></li>
                                    <li><HashLink to="/#work">FAQ</HashLink></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3  col-sm-4">
                            <div class="foot-links-wrap">
                                <h2>Get in Touch with Us</h2>
                                <ul class="foot-contact">
                                    <li>
                                        <span><FontAwesomeIcon icon={faLocationDot} /></span>
                                        123-D West US, Virgina United States.
                                    </li>
                                    <li>
                                        <span><FontAwesomeIcon icon={faPhone} /></span>
                                        <a href="tel:+123 345123 556">+123 345123 556</a>
                                    </li>
                                    <li>
                                        <span><FontAwesomeIcon icon={faEnvelope} /></span>
                                        <a href="mailto:info@sqilled.com">info@sqilled.com</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="copyright">
                    <div class="container-1470">
                        <p>Sqilled © 2020 All Rights Reserved</p>
                    </div>
                </div>
            </footer>
             {/* footer end  */}
        </>
    )
}
export default Footernew