// Component to create blogs using EDITOR.js

import { default as React, useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import { default as HeaderEditor } from "@editorjs/header";
import List from "@editorjs/list";
import ImageTool from "@editorjs/image";
import styles from "./style.css";
import { loader } from "graphql.macro";
import { withStyles } from '@material-ui/core/styles';
import { useHistory, withRouter } from 'react-router-dom';
import { withApollo } from "react-apollo";

import { toast } from "react-toastify";

import UploadFileToS3WithReactS3 from "../../utilities/uploadFileToS3WithReactS3";
import { useMutation } from "@apollo/react-hooks";
import compose from 'recompose/compose';

const BLOG_UPLOAD = loader("../../graphql/search/blogUpload.graphql");


const DEFAULT_INITIAL_DATA = () => {
  return {
    time: new Date().getTime(),
    blocks: [
      {
        type: "paragraph",
        data: {
          text: "Enter text here...",
          level: 1,
        },
      },
    ],
  };
};

const Quote = require("@editorjs/quote");
const EDITTOR_HOLDER_ID = "editorjs";

const CreateBlog = (props) => {
  const history = useHistory();
  const [editorData, setEditorData] = React.useState(DEFAULT_INITIAL_DATA);
  const [editAble, seteditAble] = useState(false);
  const [EnableEditor, setEnableEditor] = useState(false);

  const [ThumbnailUrl, setThumbnailUrl] = useState("");
  const [VideoUrl, setVideoUrl] = useState("");
  const [BlogTitle, setBlogTitle] = useState("");
  const [BlogDecp, setBlogDecp] = useState("");

  const [ThumbnailAlt, setThumbnailAlt] = useState("");

  // const ImageTool = window.ImageTool;
  const ejInstance = useRef(null);
  const initEditor = () => {
    setEnableEditor(true);
    const editor = new EditorJS({
      holder: EDITTOR_HOLDER_ID,
      logLevel: "ERROR",
      data: editorData,
      onReady: () => {
        if (ejInstance.current !== null && ejInstance.current !== undefined) {
          ejInstance.current = editor;
        }
      },
      onChange: async () => {
        let content = await editor.saver.save();
        setEditorData(content);
      },
      autofocus: true,
      tools: {
        header: HeaderEditor,
        quote: Quote,
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: "unordered",
          },
        },
        image: {
          class: ImageTool,
          
          config: {
            endpoints: {
              byFile: `http://192.168.0.26:5000/api/v1/file/image/editor`, // Your backend file uploader endpoint
            },
          },
        },
      },
    });
  };

  const postRes = (data) => {
    console.log("data.message", data.message);
  };

  const [mutate] = useMutation(BLOG_UPLOAD, {
    onCompleted({ blogUpload: { status: status, message: message } }) {
      postRes({ status, message });
    },
    onError(error) {
      console.log(error);
    },
  });


  const handleSubmit = () => {
    // console.log(editorData.blocks);
    let payload = {
      title: BlogTitle,
      // thumbnail_alt: ThumbnailAlt,
      // thumbnail: ThumbnailUrl,
      thumbnail_alt: 'null',
      thumbnail: 'null',
      description: BlogDecp,
      video_url: VideoUrl,
      text: JSON.stringify({
        value: {
          content: editorData.blocks,
        },
      }),
    };
    console.log("payload ", payload);
    props.client
      .mutate({
        mutation: BLOG_UPLOAD,
        variables: {
          data : payload
        }
      })
      .then((response) => {
        if (response) {
          // console.log(response);
          toast("Blog Created Suceessfully!")
          history.push('/my-work')
        }
      })
      .catch((error) => {
        toast(`Error ${error?.message}`)
        // console.log(error?.message);
      });
  };

  const uploadThumbnail = (thumbnail_url) => {
    setThumbnailUrl(thumbnail_url);
  };

  const uploadVideo = (Video_url) => {
    // console.log('Video_url ',Video_url)
    setVideoUrl(Video_url);
  };
  
  // console.log("ThumbnailUrl ", ThumbnailUrl);

  return (
    <>
      <section className='blogsSec'>
        <div className='container-940'>
          <div className='row m-0'>
            <div className='col-12'>
              <div className='createNewBlog'>
                <h2>Create New Blog</h2>
                <form action=''>
                  <div className='form-group'>
                    <label>Blog Title</label>
                    <input
                      type='text'
                      className='form-control'
                      onChange={(e) => setBlogTitle(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label>Blog Description</label>
                    <input
                      type='text'
                      className='form-control'
                      onChange={(e) => setBlogDecp(e.target.value)}
                    />
                  </div>

                  {/* <div className='form-group mb-0'>
                    <label>BLog Thumbnail</label>
                  </div>
                  {
                    ThumbnailUrl === '' ?
                      <UploadFileToS3WithReactS3
                        func_afterUpload={uploadThumbnail}
                        accept = 'image'
                      />
                      :
                      <div className="blog-thumbnail-preview">
                        <img src={ThumbnailUrl} />
                        <span><i class="fa-solid fa-xmark" onClick={() => setThumbnailUrl('')}></i></span>
                      </div>
                  } */}
                  
                  {/* <div className='form-group'>
                    <label>BLog Thumbnail Alt</label>
                    <input
                      type='text'
                      className='form-control'
                      onChange={(e) => setThumbnailAlt(e.target.value)}
                    />
                  </div> */}

                  <div className='form-group mb-0'>
                    <label>BLog Video</label>
                  </div>
                  {
                    VideoUrl === '' ?
                      <UploadFileToS3WithReactS3
                        func_afterUpload={uploadVideo}
                        accept = 'video'
                      />
                      :
                      <div className="blog-thumbnail-preview">
                        <video>
                          <source src={VideoUrl} />
                        </video>
                        <span><i class="fa-solid fa-xmark" onClick={() => setVideoUrl('')}></i></span>
                      </div>
                  }

                  {/* <div className='form-group'>
                    <label>BLog Video</label>
                    <input type='file' className='form-control'  />
                  </div> */}
                  <div className='form-group'>
                    <label>Blog Content</label>
                    <div className='blog-textArea'>
                      <div id={EDITTOR_HOLDER_ID}></div>
                      {!EnableEditor ? (
                        <button
                          className='editorJsbtn secondary-btn'
                          type='button'
                          onClick={() => initEditor()}>
                          {editAble === true
                            ? <span>Start Updating</span>
                            : <span>Start Creating</span>}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <button
                    type='button'
                    onClick={() => {
                      handleSubmit();
                    }}
                    className='btn primary-btn d-block ml-auto mr-0'>
                    <span>Submit</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const enhance = compose(
	withStyles(styles),
	withRouter,
	withApollo,
);
export default enhance(CreateBlog);
