import { useState,useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
// import { loader } from "graphql.macro";
import { withApollo } from "react-apollo";
// import { useMutation } from '@apollo/client';
import compose from 'recompose/compose';
import $ from 'jquery';
// import * as commonFunctions from '../../utilities/commonFunctions';
// import UserUtils from '../../utilities/userUtils';
// import useMediaRecorder from '@wmik/use-media-recorder';


// const UPLOAD_VIDEO_RECODRING = loader('../../graphql/search/uploadVideoRecording.graphql');
import { setup, isSupported } from "@loomhq/record-sdk";
import { oembed } from "@loomhq/loom-embed";

// const PUBLIC_APP_ID = "05c7adcf-9a3a-4338-b574-61176c861f9e";     //For localhost
const PUBLIC_APP_ID = "a2bfd72b-6425-4e6c-98c5-c6288264104c";     //For Domain


const BUTTON_ID = "loom-record-sdk-button";

const RecordVideo = ({flag, setFlag, videoUrl , setVideoUrl, ...props}) => {
  // function Player({ srcBlob}) {
  //   console.log(srcBlob)
  //   if (!srcBlob) {
  //     return null;
  //   }}
  // const postRes = (data) => {
  //   console.log(data, 'record respose')
  //   if (data.status === "SUCCESS") {
  //     UserUtils.setSaveVideo((data.status));
  //     props.history.push('/view-profile');
  //   }
  //   $("#loadingDiv").hide();
  // }
  // const streamCamVideo = () => {
  //   var constraints = { audio: false, video: { width: 1200, height: 1000 } };
  //   navigator.mediaDevices
  //     .getUserMedia(constraints)
  //     .then(function(mediaStream) {
  //       var video = document.querySelector("video");
  //        video.srcObject = mediaStream;
  //       video.onloadedmetadata = function(e) {
  //          video.play();
  //         }
          
  //       ;
  //     })
      
  //     .catch(function(err) {
  //       console.log(err.name + ": " + err.message);
  //     }); 
  //   }
  
  //   useEffect(()=>{
  //     streamCamVideo()
  //   },[])

  // const [mutate] = useMutation(UPLOAD_VIDEO_RECODRING, {
  //   onCompleted({
  //     uploadVideoRecording: {
  //       status: status,
  //       message: message,
  //     },
  //   }) {
  //     postRes({ status, message });
  //   },
  //   onError(error) {
  //     const err = commonFunctions.parseGraphQLErrorMessage(error);
  //     UserUtils.setSaveVideo((err));
  //     $("#loadingDiv").hide();
  //   },
  // });
  //   const stopRecordData =  async (srcBlob) => {
  //     console.log(srcBlob)
  //     const file = await new File([srcBlob], 'profile')
  //     $("#loadingDiv").show();
  //     mutate({ variables: { file } });
  //   }
    
    
  // let {
  //   stopRecording,
  //   startRecording,
  // } = useMediaRecorder({
  //   recordScreen: true,
  //   blobOptions: { type: 'video/webm' },
  //   mediaStreamConstraints: { audio: true, video: true },
  //   onStop: stopRecordData
  // });

  // const stopWebcam=()=>{
  //   var video = document.querySelector("video");
  //   try {

  //     video.srcObject.getTracks().forEach((track) => {
  //       track.stop();
  //     });
      
  //   } catch (error) {
  //     console.log(error)
  //   }
  

  // }


  const [videoHTML, setVideoHTML] = useState("");
  // const [ videoUrl , setVideoUrl ] = useState('')

  useEffect(() => {
    async function setupLoom() {
      const { supported, error } = await isSupported();

      if (!supported) {
        console.warn(`Error setting up Loom: ${error}`);
        return;
      }

      const button = document.getElementById(BUTTON_ID);

      if (!button) {
        return;
      }

      const { configureButton } = await setup({
        // env for domain
        publicAppId: PUBLIC_APP_ID,
        // env for localhost
        // publicAppId: process.env.REACT_APP_LOOM_APP_ID_LOCALHOST,
      });


      const sdkButton = configureButton({ element: button });

      sdkButton.on("insert-click", async (video) => {
        const { html } = await oembed(video.sharedUrl, { width: 400 });
        setVideoHTML(html);
      });

      sdkButton.on("upload-complete", async (video) => {
        // console.log('video upload-complete',video)
        setVideoUrl(video.embedUrl)
        // setVideoUrl(video.sharedUrl)
      });
    }
    
    setupLoom();
  }, [flag]);
  
  // console.log('videoHTML ',videoHTML);



  return (
    <>
      {/* <Link className="btn btn-default back-btn" to="/edit-profile">Back</Link>
      <p className="video-record-title">Record Self Profile Video </p>
      <section>

      <div id= "container">
          <video Play={true} id="videoElement" ></video> 
          <button type="button" onClick={startRecording}>Start Recording</button>
           <button type="button" onClick={(event)=>{stopRecording(event);stopWebcam()} }>Stop recording</button>
        </div>
      </section>
     
      <div className="record-video-profile">
        <Player
          onStop={srcBlob => {
            console.log(srcBlob)
            stopRecordData(srcBlob)
          }}
        />
       
      </div> */}
      {/* <button id={BUTTON_ID}>Record</button> */}
      <div dangerouslySetInnerHTML={{ __html: videoHTML }}></div>
    </>
  )
}
const enhance = compose(
  withRouter,
  withApollo,
);
export default enhance(RecordVideo);
