import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter , Redirect} from 'react-router-dom';
// import Unauthorized from '../../ui/unauthorized';

const renderComponent = (component, Layout, loggedInUser) => (
  <Layout component={component} loggedInUser={loggedInUser} />
);

const Gate = ({
   loggedInUser, component, layout: Layout, 
  //  ...props
}) => {
  if (loggedInUser?.email) {
    return renderComponent(component, Layout, loggedInUser);
  }
  return <Redirect to="/unauthorized"/>;
  // return <Unauthorized />;
  // return props.history.push('/login')
};

Gate.propTypes = {
  component: PropTypes.object.isRequired,
  layout: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(Gate);
