import React, { useEffect } from "react";
import { useState } from "react";
import EditorJS from "@editorjs/editorjs";
import { default as HeaderEditor } from "@editorjs/header";
import { default as ImageEditor } from "@editorjs/simple-image";
import List from "@editorjs/list";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import UserUtils from "../../utilities/userUtils";
import compose from "recompose/compose";
import UtcToLocalTime from "../../utilities/utcToLocalTime";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { loader } from "graphql.macro";


import styles from "./style.css";
import { toast } from "react-toastify";
const USER = loader("../../graphql/auth/user.graphql");
const BLOG_GET = loader("../../graphql/search/blogGet.graphql");
const BLOG_COMMENT_ADD = loader("../../graphql/blogs/blogCommentAdd.graphql");
const BLOG_COMMENT_UPDATE = loader("../../graphql/blogs/blogCommentEdit.graphql");
const BLOG_COMMENT_DELETE = loader("../../graphql/blogs/blogCommentDelete.graphql");




const Blog = (props) => {
  const history = useHistory();
  const loaction = useLocation();

  const [data, setData] = useState([]);
  const [editorData, setEditorData] = useState("");
  const [userBlog, setUserBlog] = useState({});

  const [refetchBlogData, setRefetchBlogData] = useState(false);
  const [commentText, setCommentText] = useState('')

  const [selectedComment, setSelectedComment] = useState('')

  const [commentsArray, setCommentsArray] = useState([])

  const [loginUserId, setLoginUserId] = useState(null);



  const EDITTOR_HOLDER_ID = "editorjs";
  const Quote = require("@editorjs/quote");
  const SimpleImage = require("@editorjs/simple-image");


  useEffect(() => {
    // const accessToken = UserUtils.getAccessToken();
    // if (accessToken === null) {
    //   props.history.push("/login");
    // }
    const userId = UserUtils.getUserID();
    setLoginUserId(userId);
    // console.log('userId ',userId);
    // if (userId === null) {
    //   props.history.push("/login");
    // }
    // else {
      props.client
        .query({
          query: BLOG_GET,
          variables: {
            //GET BLOG WITH ID
            data: loaction.search.split(":")[1],
          },
          fetchPolicy: "network-only",
        })
        .then((response) => {
          if (response) {
            // console.log("blog ", response);
            setUserBlog(response?.data?.blog);
            setCommentsArray(response?.data?.blog?.comments)
            let a = [];
            let text = JSON.parse(response?.data?.blog.text);
            for (let i = 0; i < text.value.content.length; i++) {
              if (text.value.content[i].type == "image") {
                text.value.content[i] = {
                  ...text.value.content[i],
                  data: {
                    ...text.value.content[i].data,
                    url: text.value.content[i].data.file.url,
                  },
                };
              }
              a.push({
                type: text.value.content[i].type,
                data: text.value.content[i].data,
              });
            }
            let b = {
              time: new Date().getTime(),
              blocks: a,
            };
            setEditorData(b);
            setData(text);
          }
        })
        .catch((error) => {
          console.log("error ", error);
        });
    // }
  }, [props, refetchBlogData]);


  const postComment = (blog_id) => {
    const author_id = UserUtils.getUserID();
    props.client.mutate({
      mutation: BLOG_COMMENT_ADD,
      variables: {
        data: {
          blog_id: blog_id,
          author_id: parseInt(author_id),
          comment: commentText,
        }
      }
    }).then(response => {
      // console.log(response);
      toast("Comment Added SuccessFully");
      setCommentText('');
      setRefetchBlogData(!refetchBlogData);
      setSelectedComment('');

    }).catch(error => {
      console.log(error);
    });
  }


  const editComment = (id) => {
    setSelectedComment(id);
  }


  useEffect(() => {
    init();
  }, [editorData]);

  const init = useCallback(() => {
    initEditor();
  }, [editorData]);

  const initEditor = () => {
    new EditorJS({
      holder: EDITTOR_HOLDER_ID,
      logLevel: "ERROR",
      data: editorData,
      autofocus: true,
      readOnly: true,
      tools: {
        header: HeaderEditor,
        quote: Quote,
        image: SimpleImage,
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: "unordered",
          },
        },
      },
    });
  };

  function BlogContent(params) {
    return (
      <div readOnly={true}>
        <div id={EDITTOR_HOLDER_ID}></div>
      </div>
    );
  }


  const handleCommnetChange = (e, id) => {
    let newArr = [...commentsArray]
    let objIndex = commentsArray.findIndex((obj => obj.id == id));
    // console.log(objIndex);
    newArr[objIndex].comment = e.target.value;
    // console.log('newArr ', newArr)
    setCommentsArray(newArr)
  }

  const updateComment = (id) => {
    var obj = commentsArray.find(item => item.id === id);
    // console.log(obj);
    props.client.mutate({
      mutation: BLOG_COMMENT_UPDATE,
      variables: {
        data: {
          comment_id: parseInt(obj?.id),
          comment: obj?.comment,
        }
      }
    }).then(response => {
      // console.log(response);
      toast("Comment Updated SuccessFully");
      setCommentText('');
      setSelectedComment('');
      setRefetchBlogData(!refetchBlogData);

    }).catch(error => {
      console.log(error);
    });
  }


  const deleteComment = (id) => {
    var obj = commentsArray.find(item => item.id === id);
    // console.log(obj);
    props.client.mutate({
      mutation: BLOG_COMMENT_DELETE,
      variables: {
        data: {
          comment_id: parseInt(obj?.id),
        }
      }
    }).then(response => {
      // console.log(response);
      toast("Comment Deleted SuccessFully");
      setCommentText('');
      setRefetchBlogData(!refetchBlogData);

    }).catch(error => {
      console.log(error);
    });
  }



  // console.log('utc created_at ',  userBlog?.created_at?.split(' ').join('T').concat('Z'))
  // console.log('userBlog ', userBlog);
  // console.log('commentsArray ', commentsArray);


  return (
    <>
      <section className='blogsSec'>
        <div className='container-940'>
          <div className='row m-0'>
            <div className='col-12'>
              <article className='blogBoxDetail'>
                <div className='autohrBox'>
                  <div className='authorImg'>
                    <img src={userBlog?.user?.avatar ? userBlog?.user?.avatar : '/images/default.png'} alt='Blogger' />
                  </div>
                  <div className='authorInfo'>
                    <h3 className='authorName'>
                      {userBlog?.user?.first_name} {userBlog?.user?.last_name}
                    </h3>
                    <ul className='articleDate'>
                      <li>
                        <UtcToLocalTime created_at={userBlog?.created_at} />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='blogContent'>
                  <h2> {userBlog?.title} </h2>
                  {
                    userBlog?.video_url ?
                      <div class="articleVideo">
                        <video controls="false" poster={userBlog.video_url} >
                          <source src={userBlog.video_url} />
                        </video>
                      </div>
                      : ''
                  }
                  <BlogContent />
                  <div className="mt-4">
                    <h4>Comments</h4>
                    <div className="blog-comment-inp-main">
                      <textarea className="w-100 mb-2" name="" id="" rows="3" value={commentText} onChange={(e) => setCommentText(e.target.value)}></textarea>
                      <button className="px-3 ml-auto d-block primary-btn" type="button" onClick={() => postComment(userBlog?.id)} ><span>Post</span></button>
                    </div>
                  </div>
                  {
                    commentsArray?.length ?
                      <div className="mt-4">
                        {
                          commentsArray?.map((comment) => {
                            return (
                              <div key={comment.id} className="blog-comment">

                                <div className="d-flex align-items-center justify-content-between">
                                  <div className='autohrBox'>
                                    <div className='authorImg'>
                                      <img src={comment?.author?.avatar ? comment?.author?.avatar : '/images/default.png'} alt='Blogger' />
                                    </div>
                                    <div className='authorInfo d-block blogComment-author'>
                                      <h3 className='authorName'>
                                        {comment?.author?.first_name} {comment?.author?.last_name}
                                      </h3>
                                      <UtcToLocalTime created_at={comment?.created_at} />
                                    </div>
                                  </div>
                                  {
                                    parseInt(loginUserId) === parseInt(comment.author_id) ?
                                      <div>
                                        <span onClick={() => editComment(comment.id)} className="f-12 actions-icon-sm" > <i class="fa-sharp fa-solid fa-pen"></i> </span>
                                        <span onClick={() => deleteComment(comment.id)} className="f-12 pl-2 actions-icon-sm" > <i class="fa-sharp fa-solid fa-trash"></i> </span>
                                      </div>
                                      :
                                      ''
                                  }
                                </div>

                                <div className="pt-2 pl-2 d-flex justify-content-between">
                                  {
                                    selectedComment === comment.id ?
                                      <div className="commentbox-editInp">
                                        <input value={comment.comment} onChange={(e) => handleCommnetChange(e, comment.id)} />
                                        <span onClick={() => updateComment(comment.id)} className="pl-2 actions-icon-sm"  > <i class="fa-solid fa-paper-plane"></i> </span>
                                      </div>
                                      : comment.comment
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                      :
                      ''
                  }
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
// export default Blog;

const enhance = compose(withStyles(styles), withRouter, withApollo);
export default enhance(Blog);
