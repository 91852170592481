import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import { SnackbarProvider } from "notistack";
import client from "./apollo/apollo-client";
import Router from "./router";
import './css/hamza.css'
import './css/responsive.css'

const App = () => (
	<>
		<ApolloProvider client={client}> 
			<ApolloHooksProvider client={client}>
			<SnackbarProvider maxSnack={3}>
				<Router />
			</SnackbarProvider>
			</ApolloHooksProvider>
		</ApolloProvider>
		<ToastContainer autoClose={3000} theme="light" />
	</>
);

export default App;
