import React, { useEffect } from 'react'
import { useState } from "react";
import moment from 'moment';

const UtcToLocalTime = ({created_at}) => {
    const [ CreatedTime, setCreatedTime ] = useState('');
    const today_date = new Date();
    const momentObj_today = moment(today_date);
    useEffect(()=>{
        if(moment.duration(momentObj_today.diff(moment(new Date(created_at?.split(' ').join('T').concat('Z')))))?._data.years > 0){
            setCreatedTime(`${moment.duration(momentObj_today.diff(moment(new Date(created_at?.split(' ').join('T').concat('Z')))))?._data.years} years ago`)
        }
        else if(moment.duration(momentObj_today.diff(moment(new Date(created_at?.split(' ').join('T').concat('Z')))))?._data.months > 0){
            setCreatedTime(`${moment.duration(momentObj_today.diff(moment(new Date(created_at?.split(' ').join('T').concat('Z')))))?._data.months} months ago`)
        }
        else if(moment.duration(momentObj_today.diff(moment(new Date(created_at?.split(' ').join('T').concat('Z')))))?._data.days > 0){
            setCreatedTime(`${moment.duration(momentObj_today.diff(moment(new Date(created_at?.split(' ').join('T').concat('Z')))))?._data.days} days ago`)
        }
        else if(moment.duration(momentObj_today.diff(moment(new Date(created_at?.split(' ').join('T').concat('Z')))))?._data.hours > 0){
            setCreatedTime(`${moment.duration(momentObj_today.diff(moment(new Date(created_at?.split(' ').join('T').concat('Z')))))?._data.hours} hours ago`)
        }
        else if(moment.duration(momentObj_today.diff(moment(new Date(created_at?.split(' ').join('T').concat('Z')))))?._data.minutes > 0){
            setCreatedTime(`${moment.duration(momentObj_today.diff(moment(new Date(created_at?.split(' ').join('T').concat('Z')))))?._data.minutes} mins ago`)
        }
        else if(moment.duration(momentObj_today.diff(moment(new Date(created_at?.split(' ').join('T').concat('Z')))))?._data.seconds > 0){
            setCreatedTime(`${moment.duration(momentObj_today.diff(moment(new Date(created_at?.split(' ').join('T').concat('Z')))))?._data.seconds} sec ago`)
        }
    }, [created_at])
    
  return (
    <span>
        {CreatedTime}
    </span>
  )
}

export default UtcToLocalTime

{/* {
    moment.duration(momentObj_today.diff(moment(new Date(userBlog?.created_at?.split(' ').join('T').concat('Z')))))?._data.days > 0
    ?
    <span>
        {moment.duration(momentObj_today.diff(moment(new Date(userBlog?.created_at?.split(' ').join('T').concat('Z')))))?._data.days} days ago
    </span>
    :
    (
        moment.duration(momentObj_today.diff(moment(new Date(userBlog?.created_at?.split(' ').join('T').concat('Z')))))?._data.hours > 0 ?
        <span>
            {moment.duration(momentObj_today.diff(moment(new Date(userBlog?.created_at?.split(' ').join('T').concat('Z')))))?._data?.hours} hours ago
        </span>
        :
        <span>
            {moment.duration(momentObj_today.diff(moment(new Date(userBlog?.created_at?.split(' ').join('T').concat('Z')))))?._data?.minutes} min ago
        </span>
    )
} */}