import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

const styles = {
  root: {
    display: 'block',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.white,
    minHeight: 'calc(100vh - 123px)',
  },
};

const EmptyLayout= ( { classes, component: Component, } ) => (
  <MuiThemeProvider theme={theme}>
    <div className={classes.root}>
      <main className={classes.content}>
        <Component />
      </main>
    </div>
  </MuiThemeProvider>
);

MuiThemeProvider.defaultProps = {
  loggedInUser: null,
};

MuiThemeProvider.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
};

export default withStyles( styles )( EmptyLayout);
