import React, { useState, useEffect } from "react";
// import styles from '../../my-work/styles.css';
import styles from './styles.css';
import Typography from '@material-ui/core/Typography';
import { withApollo } from "react-apollo";
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link, useParams, useHistory } from 'react-router-dom';
import compose from 'recompose/compose';
import UserUtils from '../../../utilities/userUtils';
import * as commonFunctions from '../../../utilities/commonFunctions';
import { loader } from "graphql.macro";
import $ from 'jquery';
import moment from 'moment';

const USER = loader('../../../graphql/auth/user.graphql');

const SearchUserBlogs = (props) => {
    const history = useHistory();
    const [blogOwnerID, setBlogOwnerID] = useState('');
    const [userBlogs, setUserBlogs] = useState('');

    const params = useParams();
    useEffect(() => {
        let id = params?.id.split(':')[1];
        setBlogOwnerID(id)
    }, [params])
    console.log('blogOwnerID ', blogOwnerID);

    useEffect(() => {
        const accessToken = UserUtils.getAccessToken();
        if (accessToken === null) {
            // props.history.push('/login');
            console.log('aaaaaaaaaaa');
        }

        const userId = UserUtils.getUserID();
        if (userId === null) {
            // props.history.push('/login');
            console.log('bbbbbbbbb');

        } else {
            $("#loadingDiv").show();
            props.client.query({
                query: USER,
                variables: {
                    data: blogOwnerID
                },
                fetchPolicy: "network-only"
            }).then(response => {
                if (response.data.user) {
                    setUserBlogs(response?.data?.user?.blog);
                }
                $("#loadingDiv").hide();
            }).catch(error => {
                console.log('error',error);
                $("#loadingDiv").hide();
            });
        }

        // return () => {
        //     UserUtils.removeSaveVideo();
        // }


    }, [props, blogOwnerID]);

    const today_date = new Date();
    const momentObj_today = moment(today_date);

    console.log('userBlogs ',userBlogs)


    return (
        <>
            <section className='blogsSec'>
                <div className='container-940'>
                    <div className='row m-0'>
                        {
                            userBlogs?.length ?
                            userBlogs?.map((blog) => {
                                return (
                                    <div
                                        className='col-12'
                                        key={blog.id}
                                        onClick={() => {
                                            history.push({
                                                pathname: '/blog',
                                                search: `id:${blog.id}`,
                                            });
                                        }}>
                                        <article className='blogBox'>
                                            <div className='autohrBox'>
                                                <div className='authorImg'>
                                                    <img src={blog?.user?.avatar ? blog?.user?.avatar : '/images/default.png'} alt='Blogger' />
                                                </div>
                                                <div className='authorInfo'>
                                                    <h3 className='authorName'>
                                                        {blog?.user?.first_name} {blog?.user?.last_name}
                                                    </h3>
                                                    <ul className='articleDate'>
    
                                                        <li>
                                                            {
                                                                moment.duration(momentObj_today.diff(moment(new Date(blog?.created_at))))?._days > 0
                                                                    ?
                                                                    <span>
                                                                        {moment.duration(momentObj_today.diff(moment(new Date(blog?.created_at))))?._days} days ago
                                                                    </span>
                                                                    :
                                                                    <span>
                                                                        {moment.duration(momentObj_today.diff(moment(new Date(blog?.created_at))))?._data?.hours} hours ago
                                                                    </span>
                                                            }
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='blogContent'>
                                                <h2> {blog.title} </h2>
                                                <p> {blog.description} </p>
                                            </div>
                                        </article>
                                    </div>
                                );
                            })
                            :
                            <h2 className="text-center m-auto"> No Blogs </h2>
                            // 'No Blogs'
                        }
                    </div>
                </div>
            </section>
        </>
    );
};

const enhance = compose(
    withStyles(styles),
    withRouter,
    withApollo
);
export default enhance(SearchUserBlogs);
